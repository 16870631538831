@import "./mixins/typography";

.headline-1 {
  @include headline-1;
}

.headline-2 {
  @include headline-2;
}

.headline-4 {
  @include headline-4;
}

.body-big {
  @include body-big;
}

.body-headline {
  @include body-headline;
}

.body {
  @include body;
}

.label {
  @include label;
}

.small {
  @include small;
}

.tiny {
  @include tiny;
}

.fw-bold {
  font-weight: 700;
}

.fw-semibold {
  font-weight: 600;
}

.fw-normal {
  font-weight: 400;
}

.lh-flat {
  line-height: 1;
}

.lh-normal {
  line-height: calc(1em + 10px);
}
