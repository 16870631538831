@import "../../styles/mixins/media";
@import "../../styles/mixins/typography";

.appointment-banner {
  padding-top: var(--sp-4);
  background: var(--palette-spinner-end);

  &__inner {
    display: flex;
    width: 100%;
    padding: var(--sp-3);
    padding-bottom: var(--sp-4);
    padding-right: var(--sp-9);
    position: relative;

    animation: 2s linear pulse-color infinite alternate;
    border-radius: 10px;
  }

  &__text {
    margin-bottom: var(--sp-2);
    color: var(--palette-white);
    word-break: break-word;
    @include body;
  }

  &__join-btn {
    width: min-content;
    &-icon {
      fill: var(--palette-blue);
    }
  }

  &__close-btn {
    position: absolute;
    top: var(--sp-2);
    right: var(--sp-2);
    background: none;

    &.__close-banner {
      &:hover,
      &:active {
        fill: var(--palette-foreground);
        background: transparent;
      }
    }
  }

  @include desktop-up {
    &__inner {
      padding: var(--sp-2) var(--sp-8);
      align-items: center;
      justify-content: center;

      &-content {
        display: flex;
        align-items: center;
      }
    }
    &__text {
      margin-bottom: 0;
      margin-right: var(--sp-6);
      @include body-big;
    }
  }
}

@keyframes pulse-color {
  0% {
    background: var(--palette-primary);
  }
  80%, 100% {
    background: var(--palette-primary-darken-1);
  }
}
