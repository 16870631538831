@import "./media";

@mixin experienceViewField {
  &__from-month,
  &__from-year {
    width: calc((50% - var(--sp-2)));
  }
  &__from-year {
    margin-left: var(--sp-4);
  }

  &__to-year {
    width: calc(50% - var(--sp-2));
    margin-left: var(--sp-4);
    margin-bottom: var(--sp-2);
  }
  &__to-month {
    width: calc(50% - var(--sp-2));
    margin-bottom: var(--sp-2);
    flex-grow: 1;
  }

  &__label {
    opacity: 0;
  }

  &__button {
    padding: 0;
  }

  @include portrait-tablet {
    &__firm,
    &__location {
      width: calc(50% - var(--sp-2));
    }
    &__location {
      margin-left: var(--sp-4);
    }

    &__from-month,
    &__from-year {
      width: calc((25% - var(--sp-2)));
    }
    &__from-year {
      margin-left: var(--sp-2);
    }

    &__to-year {
      width: calc(25% - var(--sp-2));
      margin-left: var(--sp-2);
    }
    &__to-month {
      width: calc(25% - var(--sp-2));
      flex-grow: 1;
      margin-left: var(--sp-4);
    }
  }
}
