@import "media";
@import "typography";

@mixin joinForm {
  max-width: 320px;
  &__control-wrapper {
    @include small;
    margin-top: var(--sp-4);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &.__buttons {
      margin-top: var(--sp-4);
    }
    &.__switch {
      justify-content: center;
      margin-top: var(--sp-6);
    }
  }
  &__social {
    margin-top: var(--sp-6);
  }

  &__checkbox-label {
    cursor: pointer;
    color: var(--palette-dark-link-color);
  }

  &__switch-button {
    padding: 0;
    margin-left: var(--sp-4);
    font-size: inherit;
  }

  @include portrait-tablet {
    &__control-wrapper {
      @include body;
      &.__buttons {
        margin-top: var(--sp-6);
      }
      &.__switch {
        margin-top: var(--sp-8);
      }
    }
    &__social {
      margin-top: var(--sp-8);
    }

    &__switch-button {
      margin-left: var(--sp-6);
    }
  }
}
