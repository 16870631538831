.toggle-button {
  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .__checkmark {
      background-color: var(--palette-blue);
      border: 1px solid var(--palette-blue);
      &:after {
        display: block;
        transform: translateX(0);
      }
    }

    &:focus ~ .__checkmark {
      border: 1px solid var(--rgb-palette-blue-opacity-0-80);
    }
  }
  &__container {
    align-items: center;
    display: inline-flex;
    cursor: pointer;
    height: 20px;
    gap: var(--sp-3);

    &:hover input ~ &.__checkmark {
      border: 1px solid var(--palette-blue);
    }
    &.__reverse {
      flex-direction: row-reverse;
    }

    &>.__checkmark {
      position: relative;
      display: flex;
      align-items: center;
      height: 14px;
      width: 24px;
      border-radius: 100px;
      background-color: var(--palette-neutral-lighten-1);

      &::after {
        content: "";
        position: absolute;

        width: 10px;
        height: 10px;
        left: 50%;
        transform: translateX(-100%);
        border-radius: 100%;
        background: var(--palette-white);
        transition: .4s;
      }
    }
  }
}
