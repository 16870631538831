@use "sass:color";

$foreground: #23272c;
$neutral: #8b929a;
$primary-darken-2: #05564d;
$primary-darken-1: #078074;
$primary: #09ab9a;
$primary-lighten-1: #46c0b3;
$primary-lighten-2: #9cddd7;
$negative: #ff5050;
$positive: #75c265;
$warning: #ffaa4a;
$attention: #e0f9dd;

$step: 0.25;

@mixin generate-color($name, $color) {
  --palette-#{$name}: #{$color};
  --palette-#{$name}-rgb: #{red($color)}, #{green($color)}, #{blue($color)};
}

@mixin generate-palette($name, $color) {
  @include generate-color($name, $color);
  @for $i from 1 through 2 {
    $calculatedStep: percentage($step * -$i);
    $result: scale-color($color, $lightness: $calculatedStep);
    $postfix: "-darken-" + $i;
    --palette-#{$name}#{$postfix}: #{$result};
    --palette-#{$name}#{$postfix}-rgb: #{red($result)}, #{green($result)}, #{blue($result)};
  }
  @for $i from 1 through 2 {
    $calculatedStep: percentage($step * $i);
    $result: scale-color($color, $lightness: $calculatedStep);
    $postfix: "-lighten-" + $i;
    --palette-#{$name}#{$postfix}: #{$result};
    --palette-#{$name}#{$postfix}-rgb: #{red($result)}, #{green($result)}, #{blue($result)};
  }
}

:root {
  @include generate-palette("foreground", $foreground);
  @include generate-palette("neutral", $neutral);
  @include generate-color("primary", $primary);
  @include generate-color("primary-darken-1", $primary-darken-1);
  @include generate-color("primary-darken-2", $primary-darken-2);
  @include generate-color("primary-lighten-1", $primary-lighten-1);
  @include generate-color("primary-lighten-2", $primary-lighten-2);
  @include generate-palette("negative", $negative);
  @include generate-palette("positive", $positive);
  @include generate-palette("warning", $warning);
  @include generate-palette("attention", $attention);

  /*********************/
  --palette-white: #ffffff;
  --rgb-palette-white: 255, 255, 255;
  --palette-black: #000000;
  --palette-gray: #f2f2f2;
  --palette-blue: var(--palette-primary);
  --palette-hover-blue: var(--palette-primary-darken-1);
  --palette-focus-blue: var(--palette-primary-darken-2);
  --palette-red: #ff5757;
  --palette-light-green: #e0f9dd;
  --palette-light-blue: #e7eaf0;
  --palette-lighten-blue: var(--palette-primary-lighten-2);
  --palette-notification-green: #75c265;
  --palette-hover-green: #4f9e3f;
  --palette-focus-green: #356a2a;
  --palette-notification-red: #ff5050;
  --palette-notification-yellow: #ffd350;
  --palette-notification-yellow-second: #ffc14d;

  --palette-dark-green-notification: #356a2a;
  --palette-light-green-notification: #bae1b2;
  --palette-lighten-green-notification: #e6ffe1;

  --palette-facebook-color: #1877f2;
  --palette-facebook-color-hover: #0e68dd;

  --palette-twitter-color: #1ca1f0;
  --palette-twitter-color-hover: #0c95e8;

  --palette-error-background-color: #ffe8e8;
  --palette-error-text-color: #ff5050;

  --rgb-palette-black: 0, 0, 0;
  --rgb-palette-blue: var(--palette-primary-rgb);
  --rgb-palette-black-gray: 35, 39, 44;
  --rgb-palette-dark-link-color: 139, 146, 154;
  --rgb-palette-dark-shadow: 12, 19, 26;
  --rgb-palette-book-button-shadow: 70, 192, 179;
  --rgb-palette-bg-input: 242, 244, 248;

  --palette-black-gray: #23272c;
  --palette-dark-link-color: #8b929a;
  --palette-bg-input: #f2f4f8;
  --palette-dark-link-color-darken: darken(#8b929a, 50%);

  --palette-spinner-end: #f6f7f9;

  --rgb-palette-dark-link-color-opacity-0-50: rgba(var(--rgb-palette-dark-link-color), 0.5);
  --rgb-palette-black-gray-opacity-0-50: rgba(var(--rgb-palette-black-gray), 0.5);
  --rgb-palette-black-gray-opacity-0-20: rgba(var(--rgb-palette-black-gray), 0.2);
  --rgb-palette-black-opacity-0-56: rgba(var(--rgb-palette-black), 0.56);
  --rgb-palette-blue-opacity-0-80: rgba(var(--rgb-palette-blue), 0.8);
  --rgb-palette-dark-shadow-opacity-0-20: rgba(var(--rgb-palette-dark-shadow), 0.2);
  --rgb-palette-dark-shadow-opacity-0-10: rgba(var(--rgb-palette-dark-shadow), 0.1);
  --rgb-palette-book-button-shadow-opacity-0-75: rgba(var(--rgb-palette-book-button-shadow), 0.75);
  --rgb-palette-dark-link-color-opacity-0-30: rgba(var(--rgb-palette-dark-link-color), 0.3);
  --rgb-palette-bg-input-opacity-0-50: rgba(var(--rgb-palette-bg-input), 0.5);
}
