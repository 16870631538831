@import "../palettecss";

@mixin divider($color: rgba(var(--palette-neutral-lighten-2-rgb), 0.45), $width: 12px, $position: bottom) {
  background-image: repeating-linear-gradient(90deg, $color 0, $color 50%, transparent 50%, transparent 100%);
  background-position: $position left;
  background-repeat: repeat-x;
  background-size: $width 1px;
  min-height: 1px;
}

@mixin divider-none {
  background-image: none;
}
